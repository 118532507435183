<template>
    <v-container>
        <v-row justify="center" class="pt-5">
            <!-- <h1 class="text-h4 font-weight-light" v-show="isActivatingLoginShield">Organization</h1>
            <h1 class="text-h4 font-weight-light" v-show="!isActivatingLoginShield">Login</h1> -->
        </v-row>
        <v-row justify="center" class="py-5">
            <v-col cols="12" sm="10" md="8" lg="6" xl="4" class="pa-0">
                <v-card class="px-10 py-5 pt-10 mt-5" v-if="!isViewReady">
                    <p>Please wait...</p>
                </v-card>
                <v-card class="px-10 py-5 pt-10 mt-5" v-if="isViewReady && error">
                    <p class="red--text">Service unavailable. Please try again later.</p>
                </v-card>
                <!-- TODO: for loginfront/loginshield iframe... -->
                <!-- <v-row justify="center" v-show="loginWithLoginShield" class="ma-0 pt-5" style="width: 100%;">
                    <div id="loginshield-content" style="width: 100%; height: 600px;"></div>
                </v-row> -->
            </v-col>
        </v-row>
    </v-container>
</template>

<script>
import { mapState, mapGetters } from 'vuex';
// import { loginshieldInit } from '@loginshield/realm-client-browser';
// import { client } from '@/client';
// function loginshieldInit() {
//     return null; // TODO: we should make a loginfront sdk with iframe like loginshield, so it can be used without redirecting user to another site
// }

// TODO: use loginfront sdk to login, instead of having it built in here

export default {
    data: () => ({
        isViewReady: false,
        error: false,
    }),

    watch: {
        isAuthenticatedReady(value, oldValue) {
            if (value && !oldValue) {
                this.init();
            }
        },
    },

    computed: {
        ...mapState({
            isAuthenticatedReady: (state) => state.isAuthenticatedReady,
            user: (state) => state.user,
            session: (state) => state.session,
            focus: (state) => state.focus,
            brandprofile: (state) => state.brandprofile,
        }),
        ...mapGetters({
            primaryColor: 'primaryColor',
            primaryButtonStyle: 'primaryButtonStyle',
        }),

        isAuthenticated() {
            return this.session.isAuthenticated;
        },
        isUsernameFormComplete() {
            return typeof this.username === 'string' && this.username.length > 0;
        },
        isPasswordFormComplete() {
            return typeof this.password === 'string' && this.password.length > 0;
        },
    },

    methods: {
        async init() {
            try {
                this.$store.commit('loading', { startLogin: true });
                this.resetErrors();
                if (this.$route.query.loginfront) {
                    const { isAuthenticated, intent, intent_params: intentParams } = await this.$client.site(this.brandprofile).authn.checkLogin({ token: this.$route.query.loginfront });
                    if (isAuthenticated) {
                        await this.$store.dispatch('refresh', { force: true });
                        this.redirectAfterLogin(intent, intentParams);
                        return;
                    }
                    this.$store.commit('setSession', { isAuthenticated: false });
                    this.$store.commit('user', null);
                }
                // we don't want to redirect the user based on stale information, so we force the refresh here (in case a refresh happened recently on another page before arriving here)
                await this.$store.dispatch('refresh', { force: true });
                if (this.isAuthenticated) {
                    // user is already authenticated
                    this.redirectAfterLogin();
                    return;
                }

                // user not authenticated yet, start the login process
                // NOTE: server will check organization's authentication mode and either return a redirect url to loginfront/loginshield (for 'delegate' mode) or to the organization's own website (for 'connect' mode) or to the sso provider (for 'sso' mode)
                const { redirect } = await this.$client.site(this.brandprofile).authn.startLogin({ return_url: window.location.href });
                console.log(`Login.vue init redirect: ${redirect}`);
                if (redirect) {
                    // use replace so that when user taps 'back' button from there, they won't
                    // end up being redirected again to where they just wanted to come back from
                    if (typeof window.location.replace === 'function') {
                        window.location.replace(redirect);
                    } else {
                        // TODO: also show link for user to click
                        window.location.href = redirect;
                    }
                    return;
                }

                // show the user an error message
                this.error = true;
            } catch (err) {
                console.error('start login failed', err);
                this.error = true;
            } finally {
                this.$store.commit('loading', { startLogin: false });
                this.isViewReady = true;
            }
        },
        resetErrors() {
            this.error = false;
        },
        async redirectAfterLogin(intent, intentParams = {}) {
            console.log(`Login.vue: redirectAfterLogin: intent ${intent} intentParams ${JSON.stringify(intentParams)}`);
            if (intent === 'account_dashboard' && intentParams.account_id) {
                // this.$router.push({ name: 'account-dashboard', params: { brandprofile: this.$route.params.brandprofile, accountId: intentParams.account_id } });
                this.$nav.replace({ name: 'account-dashboard', params: { accountId: intentParams.account_id } });
                return;
            }
            if (intent === 'account_settings' && intentParams.account_id) {
                // this.$router.push({ name: 'account-settings', params: { brandprofile: this.$route.params.brandprofile, accountId: intentParams.account_id } });
                this.$nav.replace({ name: 'account-settings', params: { accountId: intentParams.account_id } });
                return;
            }
            if (intent === 'account_list') {
                // this.$router.push({ name: 'account-settings', params: { brandprofile: this.$route.params.brandprofile, accountId: intentParams.account_id } });
                this.$nav.replace({ name: 'user-account-list' });
                return;
            }
            /*
            if (nextInteractionId) {
                const nextInteraction = await this.$store.dispatch('loadInteraction', nextInteractionId);
                console.log('finishLoginShield: next interaction: %o', nextInteraction);
                if (nextInteraction && nextInteraction.type) {
                    switch (nextInteraction.type) {
                    case 'require_login':
                        this.$nav.push(nextInteraction.state.redirect);
                        return;
                    default:
                        this.$nav.push({ name: 'brand-front', query: { i: nextInteractionId } });
                        return;
                    }
                }
            }
            if (this.next) {
                this.$nav.push(this.next);
                return;
            }
            */
            if (intent === 'storefront') {
                this.$nav.replace({ name: 'brand-front' });
                return;
            }
            this.$nav.replace({ name: 'brand-front' });
        },
    },

    mounted() {
        // if another view redirected here as part of a larger interaction like creating
        // an organization, there should be an interaction id in the query
        this.interactionId = this.$route.query.i;

        // if another view redirected here and already knows the username, we can pre-fill
        // the username in the login form; the user can still change it
        this.username = this.$route.query.email;

        // if another view redirected here with the intent of returning, it has to provide
        // the path and optional query parameters to redirect the user after login; the path
        // MUST be a relative path because this is not the right place for unrelated logic
        // about where we can redirect the user; the 'next' route could then redirect the
        // user to an offsite URL if needed
        const { next } = this.$route.query;
        if (typeof next === 'string' && next.startsWith('/')) {
            this.next = next;
        }
        this.init();
    },
};
</script>
